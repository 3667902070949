.App {
  display: flex;
  flex-direction: column;
  padding: 5px;
  height: 100vh;
}

.App h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

.App  .App-header {
}

.App .error-display {
}
.App .error-display pre {
  padding: 0.2rem 0.5rem;
  background-color: #AAAAAA;
  color: red;
}
